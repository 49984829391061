let myConfig = {
    baseUrl: "https://zsyd.dreamsun.net/api",
    // baseUrl: "http://localhost:9013/api",
    userKey: "USER_INFO",
    cachePrefix: "ZHONGSHANYUANDA_XCX_STORE_CRM",
}

// ajax form request
function getForm(url, data) {
    return new Promise(
        (resolve, reject) => {
            console.log(`----------- 以下是: ${url} 的打印参数 --------------- `)
            let dataArr = [];
            for (const key in data) {
                dataArr.push(`${key}=${data[key]}`)
            }
            let dataStr = dataArr.join('&')
            console.log('dataStr : ', dataStr);

            let xhr = new XMLHttpRequest();
            xhr.open('GET', myConfig.baseUrl + url + `?${dataStr}`, true);
            xhr.send();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let resData = JSON.parse(xhr.responseText);
                        if (resData.code == "200") {
                            resolve(resData)
                        } else {
                            reject(resData.msg)
                        }
                    }
                }
            };

            xhr.onerror = function (err) {
                reject(`\n ${url} err: ${err} \n`);
            }
        }
    )
}

function getJson(url, data) {
    return new Promise(
        (resolve, reject) => {
            console.log(`----------- 以下是: ${url} 的打印参数 --------------- `)
            let dataArr = [];
            for (const key in data) {
                dataArr.push(`${key}=${data[key]}`)
            }
            let dataStr = dataArr.join('&')
            console.log('dataStr : ', dataStr);

            let xhr = new XMLHttpRequest();
            xhr.open('GET', myConfig.baseUrl + url + `?${dataStr}`, true);
            xhr.setRequestHeader('Content-Type', 'application/json')
            xhr.send();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let resData = JSON.parse(xhr.responseText);
                        if (resData.code == "200") {
                            resolve(resData)
                        } else if (resData.code == "501") {
                            reject(resData.code)
                        } else {
                            reject(resData.msg + resData.data)
                        }
                    }
                }
            };

            xhr.onerror = function (err) {
                reject(`\n ${url} err: ${err} \n`);
            }
        }
    )
}

// ajax form request
function postForm(url, data) {
    return new Promise(
        (resolve, reject) => {
            console.log(`----------- 以下是: ${url} 的打印参数 --------------- `)
            let dataArr = [];
            for (const key in data) {
                dataArr.push(`${key}=${data[key]}`)
            }
            let dataStr = dataArr.join('&')
            console.log('dataStr : ', dataStr);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', myConfig.baseUrl + url, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
            xhr.send(dataStr);

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let resData = JSON.parse(xhr.responseText);
                        if (resData.code == "200") {
                            resolve(resData)
                        } else {
                            reject(resData.msg + resData.data)
                        }
                    }
                }
            };

            xhr.onerror = function (err) {
                reject(`\n ${url} err: ${err} \n`);
            }
        }
    )
}

// ajax json request
function postJson(url, data) {
    return new Promise(
        (resolve, reject) => {
            console.log(`----------- 以下是: ${url} 的打印参数 --------------- `)
            let xhr = new XMLHttpRequest();
            xhr.open('POST', myConfig.baseUrl + url, true);
            xhr.setRequestHeader('Content-Type', 'application/json')

            xhr.send(JSON.stringify(data));
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let resData = JSON.parse(xhr.responseText);
                        if (resData.code == "200") {
                            resolve(resData)
                        } else if (resData.code == "501") {
                            reject(resData.code)
                        } else {
                            reject(resData.msg + resData.data)
                        }
                    }
                }
            };

            xhr.onerror = function (err) {
                reject(`\n ${url} err: ${err} \n`);
            }
        }
    )
}

// 封装 缓存 get 方法
function cacheSetUser(v) {
    let k = myConfig.cachePrefix + myConfig.userKey
    localStorage.setItem(k, v)
}

// 封装 缓存 set 方法
function cacheGetUser() {
    let k = myConfig.cachePrefix + myConfig.userKey
    console.log("cacheGetUser ", localStorage.getItem(k))
    return JSON.parse(localStorage.getItem(k))
}

function cacheDelUser(k) {
    k = myConfig.cachePrefix + myConfig.userKey
    localStorage.removeItem(k)
}

function padZero(n) {
    return (n <= 9 ? n = '0' + n : n)

}

// 封装时间方法
function dateFormat(time, format = 'YYYY-MM-DD hh:mm:ss') {
    //  YYYY-MM-DD hh:mm:ss
    const dt = new Date(time)

    const y = dt.getFullYear()
    const m = padZero(dt.getMonth() + 1)
    const d = padZero(dt.getDay())

    const hh = padZero(dt.getHours())
    const mm = padZero(dt.getMinutes())
    const ss = padZero(dt.getSeconds())

    return format.replace('YYYY', y)
        .replace('MM', m)
        .replace('DD', d)
        .replace('hh', hh)
        .replace('mm', mm)
        .replace('ss', ss)
}

function copy(v) {
    return JSON.parse(JSON.stringify(v))
}

export {
    getForm,
    getJson,
    postForm,
    postJson,
    cacheGetUser,
    cacheSetUser,
    cacheDelUser,
    dateFormat,
    copy,
}
